var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",class:{
        'expansion-panel': true,
        'expansion-panel--disabled': _vm.disabled,
        'expansion-panel--expanded': _vm.isExpanded,
    }},[_c('div',{staticClass:"expansion-panel__head"},[_c('div',{class:{
            'expansion-panel__title': true,
            'expansion-panel__title--nopadding': _vm.isExpanded,
        }},[_vm._t("head",null,null,{ toggleExpand: _vm.toggleExpand, isExpanded: _vm.isExpanded })],2),(!_vm.isExpanded)?_c('button',{staticClass:"expansion-panel__expand-trigger",attrs:{"disabled":_vm.disabled},on:{"click":_vm.toggleExpand}},[_c('i',{staticClass:"icon-dropdon-icon"})]):_vm._e()]),_c('div',{ref:"body",staticClass:"expansion-panel__expansion-wrapper"},[_c('div',{staticClass:"expansion-panel__body"},[_vm._t("body",null,null,{ isExpanded: _vm.isExpanded })],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }