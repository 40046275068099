
import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import InsightsService, { InsightsServiceS } from '@/modules/insights/insights.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import NUMBER_OF_GUESTS from '@/modules/document-filters/constants/number-of-guests.constant';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import Item from '@/modules/common/interfaces/item.interface';
import FEATURES from '@/modules/common/constants/features.constant';
import { DefaultFilters } from '@/modules/user/types';
import InsightFiltersService, { InsightFiltersServiceS } from '@/modules/document-filters/insight-filters.service';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';
import SelectSection from '../select-section.vue';

@Component({
    components: { SelectSection },
})
export default class DefaultFiltersTab extends SettingsTab {
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(InsightsServiceS) private insightsService!: InsightsService;
    @Inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;
    @Inject(UserServiceS) private userService!: UserService;

    static title = 'settings.default.title';

    status = '';
    statusColor = 'green';
    isSaving = false;

    private localFilters = {} as DefaultFilters;

    mapProviders = [
        { name: 'Google', value: 'Google' },
        { name: 'OpenStreetMap', value: 'OpenStreetMap' },
    ];

    get isValid() {
        if (!this.localFilters.targetedInsights) {
            return true;
        }

        this.statusColor = 'red';

        const isOneInsightAtLeast = Object.values(this.localFilters.targetedInsights).some(v => v);

        if (!isOneInsightAtLeast) {
            this.status = this.$tc('settings.default.selectInsights');
        } else {
            this.statusColor = 'green';
            this.status = '';
        }

        return isOneInsightAtLeast;
    }

    get shownInsightsTypes() {
        const values = this.localFilters.targetedInsights || this.insightsService.targetedInsights;

        return Object.keys(values)
            .filter(key => values[key]);
    }

    set shownInsightsTypes(v: string[]) {
        const newValue = this.insightTypesItems.reduce((acc, item) => {
            acc[item.value] = v.includes(item.value);

            return acc;
        }, {} as Record<string, boolean>);

        this.localFilters = {
            ...this.localFilters,
            targetedInsights: newValue,
        };
    }

    get insightTypesItems() {
        return this.insightFiltersService.options.insightTypes;
    }

    get mapProvider() {
        return this.localFilters.mapProvider || this.userSettingsService.defaultFilters.mapProvider;
    }

    set mapProvider(v: string) {
        this.localFilters = {
            ...this.localFilters,
            mapProvider: v,
        };
    }

    get los() {
        return this.localFilters.los || this.userSettingsService.defaultFilters.los;
    }

    set los(v: number) {
        this.localFilters = {
            ...this.localFilters,
            los: v,
        };
    }

    get mealType() {
        return this.localFilters.mealType || this.userSettingsService.defaultFilters.mealType;
    }

    set mealType(v: string) {
        this.localFilters = {
            ...this.localFilters,
            mealType: v,
        };
    }

    get numberOfGuests() {
        return this.localFilters.numberOfGuests || this.userSettingsService.defaultFilters.numberOfGuests;
    }

    set numberOfGuests(v: number) {
        this.localFilters = {
            ...this.localFilters,
            numberOfGuests: v,
        };
    }

    get price() {
        return this.localFilters.price || this.userSettingsService.defaultFilters.price;
    }

    set price(v: string) {
        this.localFilters = {
            ...this.localFilters,
            price: v,
        };
    }

    get losItems() {
        return DEFAULT_LOS.map(v => ({
            name: String(v),
            value: v,
        } as Item));
    }

    get mealTypeItems() {
        return this.mealTypesService.mealTypeItems;
    }

    get priceItems() {
        return $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`price.${value}`) as string,
        }));
    }

    get nogItems() {
        return NUMBER_OF_GUESTS.map(v => ({
            name: String(v),
            value: v,
        } as Item));
    }

    get isChanged() {
        return !!Object.values(this.localFilters).find(v => v !== null);
    }

    get isInsightsEnabled() {
        return this.userService.enabledFeatures![FEATURES.INSIGHTS];
    }

    async handleUpdate() {
        this.isSaving = true;
        try {
            await this.userSettingsService.updateDefaultFilters(this.localFilters);

            if (this.localFilters.targetedInsights) {
                this.insightFiltersService.loading.insightTypes.reset();
            }
            this.status = this.$tc('saved');
            this.statusColor = 'green';
            this.localFilters = {};
        } catch (e) {
            this.status = this.$tc('error');
            this.statusColor = 'red';
            throw e;
        } finally {
            this.isSaving = false;
        }
    }
}
