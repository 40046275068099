import { render, staticRenderFns } from "./currency.section.vue?vue&type=template&id=178f5c17&scoped=true&"
import script from "./currency.section.vue?vue&type=script&lang=ts&"
export * from "./currency.section.vue?vue&type=script&lang=ts&"
import style0 from "./currency.section.vue?vue&type=style&index=0&id=178f5c17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "178f5c17",
  null
  
)

export default component.exports